import store from '@/boot/store'

/**
 * Returns true if url is loading, else false
 *
 * @param {String} url The url to check
 * @param {String} method HTTP request method (default: 'GET')
 */
export function isLoading(url, method = 'GET') {
  return !!store.getters['core/apiEndpointIsLoading']({ method, url })
}
