<template>
  <HubSelectField
    :disabled="disabled"
    v-model="selectedRegion"
    name="origin-region"
    class="modal-form__field"
    @change="onSelect($event)"
    :items="items"
    ref="region"
    :label="label || $t('billing.originRegion')"
    :error-messages="errors || (errorMessages && errorMessages.originRegion)"
  />
</template>

<script>
export default {
  name: 'RegionDropdown',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Object,
    },
    currentRegion: {
      type: String,
    },
    label: {
      type: String,
    },
    errors: {
      type: Array,
    },
  },
  data() {
    return {
      selectedRegion: this.currentRegion,
    }
  },
  methods: {
    onSelect(e) {
      this.selectedRegion = e
      this.$emit('region', this.selectedRegion)
      this.$emit('reset')
    },
    clearData() {
      this.selectedRegion = ''
    },
  },
  watch: {
    currentRegion: function(newValue) {
      this.selectedRegion = newValue
    },
  },
}
</script>
