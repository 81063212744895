const ModalActionsMixin = {
  data() {
    return {
      editRequiresAdmin: true,
    }
  },

  methods: {
    openCreateModal() {
      this.createModal = true
    },

    onModalClose() {
      this.editModal = false
      this.selected = null
    },

    closeCreateModal() {
      this.createModal = false
    },

    openConfirmationModal(selectedRow) {
      this.selected = selectedRow
    },

    onRowSelected(v) {
      this.selected = v
      if (this.editRequiresAdmin && !this.isAdmin) return
      this.editModal = true
    },

    onUpdatedItem() {
      this.fetch()
    },
  },
}

export default ModalActionsMixin
