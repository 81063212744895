<template>
  <div class="fields-container">
    <hub-text-field
      @keyup="onInputFocus('description')"
      :error-messages="(errorMessages && errorMessages.description) || []"
      class="modal-form__field"
      name="description"
      v-model="formData.description"
      :label="$t('skuSpecificPricing.fields.description')"
    />
    <hub-text-field
      @keyup="onInputFocus('hubooSku')"
      :error-messages="(errorMessages && errorMessages.hubooSku) || []"
      class="modal-form__field"
      name="hubooSku"
      v-model="formData.hubooSku"
      :label="$t('skuSpecificPricing.fields.hubooSku')"
    />
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('price')"
      :error-messages="(errorMessages && errorMessages.price) || []"
      class="modal-form__field"
      name="price"
      v-model="formData.price"
      :label="$t('skuSpecificPricing.fields.price')"
    />
    <RegionDropdown
      ref="regionDropdown"
      @reset="onInputFocus('originRegion')"
      :items="operatingRegions"
      @region="updateRegion($event)"
      :error-messages="errorMessages"
      :current-region="formData.originRegion"
    />
    <div class="break" />
    <HubSwitch
      @input="toggleQuantityCharge"
      :value="formData.quantityCharge"
      :label="$t('skuSpecificPricing.fields.quantityCharge')"
      name="quantity-charge-toggle"
      :error-messages="(errorMessages && errorMessages.quantityCharge) || []"
    />
    <div class="break" />
    <HubSwitch
      :disabled="!isAdmin"
      @input="toggleIsExclusive"
      :value="formData.isExclusive"
      :label="$t('skuSpecificPricing.fields.isExclusive')"
      name="is-exclusive-toggle"
      :error-messages="(errorMessages && errorMessages.isExclusive) || []"
    />
    <div class="break" />
    <HubSwitch
      :disabled="!isAdmin"
      @input="toggleIsStorageBillable"
      :value="formData.isStorageBillable"
      :label="$t('skuSpecificPricing.fields.isStorageBillable')"
      name="is-storage-billable-toggle"
      :error-messages="(errorMessages && errorMessages.isStorageBillable) || []"
    />
  </div>
</template>

<script>
import RegionDropdown from '@/components/forms/RegionDropdown.vue'

export default {
  name: 'InputFields',
  components: { RegionDropdown },
  props: {
    formData: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    errorMessages() {
      return this.$store.getters['core/validationErrors']
    },
    isAdmin() {
      return this.$store.getters['core/getIsAdmin']
    },
    operatingRegions() {
      return this.$store.getters['billing/getOriginRegions']?.map(data => ({
        text: data.code,
        value: data.code,
      }))
    },
  },
  methods: {
    onInputFocus(inputName) {
      this.removeError(inputName)
    },
    updateRegion(e) {
      this.formData.originRegion = e
    },
    removeError(field) {
      this.$store.commit('core/removeError', field)
    },
    toggleQuantityCharge() {
      this.formData.quantityCharge = !this.formData.quantityCharge
    },
    toggleIsExclusive() {
      this.formData.isExclusive = !this.formData.isExclusive
    },
    toggleIsStorageBillable() {
      this.formData.isStorageBillable = !this.formData.isStorageBillable
    },
  },
  watch: {
    formData() {
      this.$refs.regionDropdown.clearData()
    },
  },
}
</script>

<style>
.fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.fields-container > * {
  flex-grow: 2;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.hub-switch {
  margin: 0;
  height: 1.25rem;
}
</style>
