<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('skuSpecificPricing.tablePageTitle') | title" />

    <huboo-table
      id="skuSpecificPricingTable"
      @filter="handleFilter"
      @row-clicked="onSelected"
      @search="handleSearch"
      @update:options="handleUpdateOptions"
      @remove="openConfirmationModal"
      @confirm-delete="onRemove"
      :filters="filters"
      :headers="headers"
      :items="items"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      clickable
      hide-search
      localPagination
      :remove="isAdmin"
      v-bind="options"
    >
      <template #actions>
        <export-button
          v-if="exportUrl"
          :file-name="exportName"
          :export-url="exportUrl"
          :client="box.hubooBox.toString()"
        />
        <v-btn
          :loading="createModalLoading"
          :disabled="loading"
          color="primary"
          @click="openCreateModal"
        >
          {{ $t('skuSpecificPricing.createModal.createButton') }}
        </v-btn>
      </template>
    </huboo-table>
    <edit-modal
      v-model="editModal"
      :selected="selected"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
    />
    <create-modal v-model="createModal" @close="closeCreateModal" @updated-item="onUpdatedItem" />
  </huboo-page>
</template>

<script>
import { handleSearch } from '@/views/pages/billing/billingViewHelpers.js'
import { title } from '@/utilities/filters'
import { isLoading } from '@/utilities/helpers/views'
import ClientMixin from '@/mixins/client'
import ModalActionsMixin from '@/mixins/modalActions'
import ExportButton from '@/components/pages/ExportButton.vue'
import EditModal from './SkuSpecificPricingEditModal.vue'
import CreateModal from './SkuSpecificPricingCreateModal.vue'

export default {
  name: 'SkuSpecificPricing',
  mixins: [ClientMixin, ModalActionsMixin],
  components: {
    'export-button': ExportButton,
    'edit-modal': EditModal,
    'create-modal': CreateModal,
  },
  data: () => ({
    createModal: false,
    editModal: false,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
  }),
  computed: {
    createModalLoading() {
      return isLoading(this.createUrl, 'POST')
    },
    createUrl() {
      return 'sku-specific-price-list/'
    },
    exportUrl() {
      return this.$store.getters['skuSpecificPricing/getExportUrl']
    },
    skuSpecificPricingHeaders() {
      return [
        {
          text: title(this.$t('skuSpecificPricing.fields.hubooSku')),
          sortable: false,
          value: 'hubooSku',
        },
        {
          text: title(this.$t('skuSpecificPricing.fields.description')),
          sortable: false,
          value: 'description',
        },
        {
          text: title(this.$t('skuSpecificPricing.fields.price')),
          sortable: false,
          value: 'price',
        },
        {
          falseText: this.$t('common.no'),
          text: title(this.$t('skuSpecificPricing.fields.quantityCharge')),
          sortable: false,
          trueText: this.$t('common.yes'),
          value: 'quantityCharge',
        },
        {
          falseText: this.$t('common.no'),
          text: title(this.$t('skuSpecificPricing.fields.isExclusive')),
          sortable: false,
          trueText: this.$t('common.yes'),
          value: 'isExclusive',
        },
        {
          falseText: this.$t('common.no'),
          text: title(this.$t('skuSpecificPricing.fields.isStorageBillable')),
          sortable: false,
          trueText: this.$t('common.yes'),
          value: 'isStorageBillable',
        },
        {
          text: title(this.$t('skuSpecificPricing.fields.originRegion')),
          sortable: true,
          value: 'originRegion',
        },
        {
          text: title(this.$t('skuSpecificPricing.fields.updatedAt')),
          sortable: false,
          value: 'updatedAt',
        },
      ]
    },
    getSkuSpecificPriceLists() {
      return this.$store.getters['skuSpecificPricing/getAll']
    },
    headers() {
      return this.skuSpecificPricingHeaders
    },
    items() {
      return this.skuSpecificPriceLists.map(bh => {
        const v = { ...bh }
        v.updatedAt = this.formatDate(v.updatedAt)
        return v
      })
    },
    loading() {
      return isLoading(this.url)
    },
    meta() {
      return this.getSkuSpecificPriceLists?.meta || { total: this.items.length }
    },
    skuSpecificPriceLists() {
      const lists = this.getSkuSpecificPriceLists
      if (Array.isArray(lists))
        return lists.map(l => ({
          ...l,
          loading: this.removeLoading(l.id),
        }))
      else return []
    },
    url() {
      return '/huboo-box/' + this.box?.hubooBox + this.urlEntity
    },
    urlEntity() {
      return '/sku-specific-price-list/'
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch('billing/fetchRegions')
  },
  methods: {
    fetch() {
      const { options, search } = this
      const { page } = options
      const params = {}
      if (page) params.page = page
      if (search) params.query = search
      return this.$store.dispatch('skuSpecificPricing/fetchCollection', { params })
    },
    handleSearch(e) {
      return handleSearch(this, e)
    },
    async onRemove() {
      await this.$store.dispatch('skuSpecificPricing/delete', this.selected.id)
    },
    openConfirmationModal(selectedRow) {
      this.selected = selectedRow
    },
    onSelected(e) {
      const selectedArray = Object.entries(e)
      const stringToBool = selectedArray.map(item => {
        if (item[1] === 'Yes') {
          return [item[0], true]
        }
        if (item[1] === 'No') {
          return [item[0], false]
        } else {
          return item
        }
      })

      let newSelectedObject = stringToBool.reduce((acc, item) => {
        acc[item[0]] = item[1]
        return acc
      }, {})

      this.selected = newSelectedObject
      this.editModal = true
    },

    onUpdatedItem() {
      this.fetch()
    },

    removeLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.urlEntity + id,
        method: 'DELETE',
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
