<template>
  <modal-form
    :value="value"
    id="edit-sku-specific-pricing-form"
    lazy-validation
    :loading="loading"
    :fields="fields.formData"
  >
    <template #header>
      {{ $t('skuSpecificPricing.editModal.title') }}
    </template>

    <template #fields>
      <InputFields :form-data="fields.formData" :key="key" />
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="$emit('close')" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import InputFields from './InputFields.vue'

export default {
  name: 'SkuSpecificPricingEditModal',
  props: {
    value: Boolean,
    selected: Object,
  },
  components: {
    InputFields,
  },

  computed: {
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: '/sku-specific-price-list/' + this.selected?.id,
      })
    },

    fields() {
      return {
        formData: {
          description: this.selected?.description,
          hubooSku: this.selected?.hubooSku.toString(),
          price: this.selected?.price.toString(),
          originRegion: this.selected?.originRegion,
          quantityCharge: this.selected?.quantityCharge,
          isExclusive: this.selected?.isExclusive,
          isStorageBillable: this.selected?.isStorageBillable,
        },
      }
    },

    key() {
      return this.selected ? this.selected.id : 'none'
    },
  },

  methods: {
    async onSubmit() {
      const res = await this.$store.dispatch('skuSpecificPricing/update', {
        id: this.selected?.id,
        data: {
          hubooBox: this.selected.hubooBox,
          ...this.fields.formData,
          price: parseFloat(this.fields.formData.price),
          hubooSku: parseInt(this.fields.formData.hubooSku),
        },
      })
      if (res?.status !== 200) return

      this.$emit('close')
      this.$emit('updated-item')
    },
  },
}
</script>
