<template>
  <modal-form
    :value="value"
    id="sku-specific-create-pricing-form"
    lazy-validation
    :loading="loading"
    :fields="formData"
    :active-field="activeField"
  >
    <template #header>
      {{ $t('skuSpecificPricing.createModal.title') }}
    </template>

    <template #fields>
      <InputFields :form-data="formData" />
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="closeAndClear" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import InputFields from './InputFields.vue'

export default {
  name: 'SkuSpecificPricingCreateModal',
  components: {
    InputFields,
  },
  props: {
    value: Boolean,
  },

  data() {
    return {
      formData: {
        description: '',
        hubooSku: null,
        price: null,
        originRegion: '',
        quantityCharge: false,
        isExclusive: false,
        isStorageBillable: true,
      },
      activeField: '',
    }
  },

  computed: {
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: '/sku-specific-price-list/',
      })
    },
  },

  methods: {
    async onSubmit() {
      const box = this.$store.getters['billing/getSelectedBox']

      const res = await this.$store.dispatch('skuSpecificPricing/create', {
        data: {
          hubooBox: box?.hubooBox,
          ...this.formData,
          price: parseFloat(this.formData.price),
          hubooSku: parseInt(this.formData.hubooSku),
        },
      })

      if (res?.status !== 201) return

      this.$emit('close')
      this.$emit('updated-item')
      this.formData = {
        description: '',
        hubooSku: null,
        price: null,
        originRegion: '',
        quantityCharge: false,
        isExclusive: false,
        isStorageBillable: true,
      }
    },

    closeAndClear() {
      this.$emit('close')
      this.formData = {
        description: '',
        hubooSku: null,
        price: null,
        originRegion: '',
        quantityCharge: false,
        isExclusive: false,
        isStorageBillable: true,
      }
    },
  },
}
</script>

<style>
.fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.fields-container > * {
  flex-grow: 2;
}
.break {
  flex-basis: 100%;
  height: 0;
}
</style>
